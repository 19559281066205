<template>
  <div class="_bg-default _80w _100vh d-flex">
    <div class="_100top _60-w pa-3" v-if="feed">
      <!-- <v-card class="radius-card pa-3">
        <v-btn
          rounded
          depressed
          block
          class="d-flex justify-start grey--text"
          @click="dialogFeed = true"
          ><v-icon class="mr-2">mdi-pencil-box-outline</v-icon> Mulai buat
          postingan</v-btn
        >
      </v-card> -->

      <v-btn dark depressed to="/psikolog/personal" color="green" rounded>
        <v-icon>mdi-reply</v-icon> <b>Back</b></v-btn
      >

      <div
        class="d-flex flex-column align-center mt-10"
        v-if="!feed.data.length"
      >
        <img src="../../assets/img/404.svg" height="200px" alt="" />
        <h3>No Feeds Available</h3>
      </div>
      <div class="my-4" v-for="(q, idx) in feed.data" :key="`${idx}key`">
        <v-card class="radius-card">
          <section class="pa-3">
            <div class="d-flex">
              <v-avatar class="mr-3">
                <img
                  :src="
                    q.relationships[0].user.data.photo_profile
                      ? `${env}/upload/photo_profile/${q.user_id}/${q.relationships[0].user.data.photo_profile}`
                      : `${env}/images/logo.png`
                  "
                  class="img-fit"
                  alt=""
                />
              </v-avatar>
              <div>
                <h4>{{ q.relationships[0].user.data.username }}</h4>
                <small class="grey--text">{{
                  $date(q.created_at).fromNow()
                }}</small>
              </div>
            </div>
          </section>
          <section class="px-5">
            <h3>{{ q.content }}</h3>
          </section>
          <v-card-actions @click="toView(q.id)">
            <v-spacer></v-spacer>
            <v-btn text color="blue">
              {{ q.relationships[1].comments.data.length }}
              <v-icon>mdi-comment</v-icon>
            </v-btn>

            <v-btn text color="blue">
              {{ q.relationships[2].like.data }}
              <v-icon>mdi-heart</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <CreateFeed @false="closeDialog" v-bind:dialogFeed="dialogFeed" />
    </div>
    <div class="_recent _100top _10w _100vh">
      <v-col cols="12" md="12">
        <v-card class="radius-card pa-3">
          <h4>Recent Activities</h4>
          <v-list three-line>
            <template v-for="act in activity">
              <v-list-item v-if="act" :key="act">
                <v-list-item-avatar>
                  <v-img
                    :src="
                      act.photo_profile
                        ? `${env}/upload/photo_profile/${act.causer_id}/${act.photo_profile}`
                        : `${env}/images/logo.png`
                    "
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="act.username"></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="act.description"
                  ></v-list-item-subtitle>
                  <small class="grey--text">{{
                    $date(act.created_at).fromNow()
                  }}</small>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CreateFeed from "../../components/Psycholog/Modal/createFeed.vue";

export default {
  components: { CreateFeed },
  data() {
    return {
      dialogFeed: false,
      items: [
        { header: "Today" },
        {
          avatar:
            "https://i.pinimg.com/564x/51/09/a2/5109a27c8d9684202aff5e6e2249cd0b.jpg",
          title: "Brunch this weekend?",
          subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`
        },
        { divider: true, inset: true }
      ]
    };
  },
  computed: {
    ...mapState({
      feed: state => state.feeds.getFeeds,
      feedDetail: state => state.feeds.feedsDetail,
      role: state => state.role,
      env: state => state.API_URL,
      activity: state => state.feeds.activity
    })
  },
  mounted() {
    this.fetchFeed();
    this.fetchActivity();
  },
  methods: {
    fetchFeed() {
      this.$store.dispatch("feeds/myFeeds");
    },
    fetchActivity() {
      this.$store.dispatch("feeds/listActivity");
    },
    closeDialog() {
      this.dialogFeed = false;
      this.fetchFeed();
    },
    toView(id) {
      this.$router.push(`/psikolog/feed/${id}`);
    }
  }
};
</script>

<style scoped>
._60-w {
  width: 60%;
}
._10w {
  width: 40%;
  padding-right: 20px;
}
@media (max-width: 576px) {
  ._60-w {
    width: 100%;
  }
  ._recent {
    display: none !important;
  }
}
@media (max-width: 768px) {
  ._60-w {
    width: 100%;
  }
  ._recent {
    display: none !important;
  }
}
</style>
